import React, { useEffect, useState, useContext, useRef } from "react";
import { error as E } from "@ocean-knight/shared";
import { dummy } from "@ocean-knight/shared";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { POPUP as GeneralPopup } from "../../common/defines";
import Api from "../../Api";
import PostcodeWindow from "../PostcodeWindow";
import PostcodeForm from "../common/PostcodeForm";
import PhoneForm from "../common/PhoneForm";
import common from "../../common/common";
import { AppContext } from "../../AppContext";
import GeneralErrorWindow from "../common/GeneralErrorWindow";
import { Button, Container, Row, Col } from "react-bootstrap";
import Modal from "../common/Modal";
import "./SignupForm.css";
import { RequestMailCodeWindow, WrongMailCodeWindow } from "../common/MailAuthenticationWindow";
import { InputText } from "../Input";
import LoadingSpinner from "../common/LoadingSpinner";
import dgLogger from "../../common/dgLogger";
import { useTranslation, Trans } from "react-i18next";
import { sendMessageToMobileAppWebview } from "../common/RNBridge";
import { update } from "lodash";

const ID = {
    mail: "registered-email",
    mailFeedback: "mail-feedback",
    realname: "register-realname",
    nickname: "register-nickname",
    duplicatedName: "register-duplicated-name",
    verifyMailCode: "verify-mail-code",
    postcodePopup: "register-postcode-popup",
    mailButton: "register-mail-button",
    phone: "register-phone",
    address1: "register-address1",
    address2: "register-address2",
    about: "register-about",
    sns: "register-sns",
    duplicateButton: "register-duplicate-button"
};

const POPUP = {
    ...GeneralPopup,
};

export default function UpdateMembership() {
    const location = useLocation();

    const { t } = useTranslation();
    const context = useContext(AppContext);
    const [mail, /*setMail*/] = useState({ state: E.VERIFIED, address: location.state?.email || null });
    const [state, setState] = useState({
        popup: POPUP.None,
        popupTarget: null,
        isLoading: false,
    });

    const navigate = useNavigate();
    const [errors, setErrors] = useState({ email: '', realname:'', nickname: '', phone: '', address1: '' });

    useEffect(() => {
        document.getElementById("App").className = "login";

        // console.log("locations state : ", location.state);

        // 전달된 state 에 email, (name) 이 없다면 에러 팝업
        if (!location.state?.email || !location.state?.type) {
            setState({ isLoading: false, popup: POPUP.GeneralError, popupTarget: null });
        }
    }, [location]);

    /**
     * 중복된 닉네임(ID) 가 있는지 확인및 UI 상에 validation check
     *
     * @returns true means has no duplicate nickname
     */
    const onDuplicateNickname = async () => {
        try {
            const nickname = document.getElementById(ID.nickname).value.trim();
            if (!nickname) { return false; }

            if (state.needCheckDuplicateNickname) {
                await Api.isDuplicatedUserName({ name: nickname });
            }
            setErrors({ ...errors, nickname: { state: true, message: t("323")} });
            document.getElementById(ID.nickname).classList.remove("invalid");
            return true;
        } catch (e) {
            setErrors({ ...errors, nickname: { state: false, message: t("324")} });
            document.getElementById(ID.nickname).classList.add("invalid");
            return false;
        }
    };

    const onSubmit = async (e) => {
        const email = document.getElementById(ID.mail);
        const nickname = document.getElementById(ID.nickname);
        const realname = document.getElementById(ID.realname);
        const phone = document.getElementById(ID.phone);
        const address1 = document.getElementById(ID.address1);
        const address2 = document.getElementById(ID.address2);
        const about = document.getElementById(ID.about);
        const sns = document.getElementById(ID.sns);
        let valid = true;

        if (!isValid([nickname]) || !await onDuplicateNickname()) valid = false;
        isValid([realname]) || (valid = false);
        if (isValid([email]) && mail.state !== E.VERIFIED) {
            const errorCollection = { email: { state: false, message: t("321") } };
            email.classList.add("invalid");
            valid = false;
            setErrors((prev) => ({
                ...prev, ...errorCollection
            }));
        }

        if (!valid) {
            common.scrollToInvalidElement();
            return;
        }

        setState({ ...state, isLoading: true });

        // console.log("location state : ", location.state);

        const payload = {
            realname: realname.value.trim(),
            email: location.state.email,
            password: location.state.password,
        };

        const updated = await Api.updateUserInfoV2(payload);
        if (updated) {
            Api.signInV2({
                authProvider: "email",
                email: location.state.email,
                password: location.state.password,
                autoLogin: location.state.autoLogin,
                displayLang: common.getLang(),
            })
                .then((payload) => {
                    return Api.getCurrentUserInfo({ optPermissions: true });
                })
                .then(async (payload) => {
                    sendMessageToMobileAppWebview({type: "logged-in", login: true, autoLogin: location.state.autoLogin, _id: payload._id});

                    const isReportSnapshot = await Api.fetchReportSnapshot({ registered_by: payload._id, as_boolean: true });
                    if (isReportSnapshot) {
                        localStorage.setItem(payload._id, isReportSnapshot);
                    }
                    else {
                        localStorage.removeItem(payload._id);
                    }

                    sessionStorage.setItem("name", payload.name);
                    sessionStorage.setItem("_id", payload._id);
                    common.storePermissionsInfo(payload.optPermissions);

                    const prevPathname = sessionStorage.getItem("prevPathname")?.split(",") || [];
                    let navigatePathname = undefined;
                    do {
                        navigatePathname = prevPathname.splice(-1)[0];
                    } while (navigatePathname?.startsWith("/Login"));

                    sessionStorage.setItem("prevPathname", prevPathname);

                    context.setLoggedIn(true);
                    context.setLoginProvider("email");
                    // console.log("login provider on sign-in : ", "email");

                    navigate(navigatePathname || "/", { replace: true });
                })
                .catch((e) => {
                    dgLogger.error(e)();
                    setState(prev => ({ ...state, popup: POPUP.LoginFailure, popupTarget: { provider: "email", error: e }}));
                    localStorage.removeItem("autoLogin");
                    context.setLoggedOut();
                });
        } else {
            Api.logout().finally(() => {
                localStorage.removeItem("autoLogin");
                context.setLoggedOut();
                setState({ popup: POPUP.GeneralError, popupTarget: null });
            });
        }
    };

    const popups = () => {
        switch (state.popup) {
            case POPUP.GeneralError:
                return <GeneralErrorWindow
                message={state.popupTarget}
                onClick={() => {
                    navigate("/");
                }}
            />;
            case POPUP.None: break;
            default:
                console.log(`Can not handle ${state.popup} popup type`);
        }
    };

    const isValid = (elements) => {
        const [flag, errorCollection] = common.isValid(elements);

        setErrors((prev) => ({
            ...prev, ...errorCollection
        }));
        return flag;
    };

    return (
        <Container>
            <LoadingSpinner isOpen={state.isLoading} />
            <div className="login-sub-title">
                {/* TODO: apply string table */}
                <div className="adobe-gothic-std-75 font-size-75 adobe-gothic-std-34:sm c-fff login">Update membership</div>
                <div className="notosanskr-600 font-size-24 notosanskr-14b:sm c-fff">{t("904")}</div>
            </div>
            <div
                className="signup-form-page"
                style={{
                    textAlign: "left",
                }}
            >
                {popups()}

                <div className="signup notosanskr-600 font-size-40">
                    <span>{t("904")}</span>{" "}
                </div>
                {/*email*/}
                <Row className="gx-0 first-row">
                    <Col className="w-200px col-sm col-sm-auto title-wrap" xs={12}>
                        <div className="title email-address-title notosanskr-500 font-size-17">
                            <span className="check-icon"></span>
                            {t("284")}
                        </div>
                    </Col>
                    <Col className="notosanskr-400 font-size-14 col-sm item-wrap" xs={12}>
                        <div className="email-wrap">
                            <InputText className="item" name="email" id={ID.mail} placeholder={t("293")}
                                onBlur={(e) => isValid([e.target])}
                                onKeyPress={common.blankBlocking}
                                disabled={mail.state === E.VERIFIED}
                                defaultValue={mail.address}
                            />
                            <div className="notosanskr-400 font-size-14 errors c-f00">{errors.email?.message}</div>
                        </div>
                    </Col>
                </Row>
                {/*real-name*/}
                <Row className="gx-0">
                    <Col className="w-200px col-sm col-sm-auto title-wrap" xs={12}>
                        <div className="title name-title notosanskr-500 font-size-17">
                            <span className="check-icon"></span>
                            {t("896")}
                        </div>
                    </Col>
                    <Col className="notosanskr-400 font-size-14 col-sm item-wrap" xs={12}>
                        <div>
                            <InputText
                                className="item"
                                id={ID.realname}
                                defaultValue={location.state?.realname || ""}
                                name="realname"
                                placeholder={t("908")}
                                onBlur={async (e) => {
                                    isValid([e.target]);
                                }}
                                maxLength={20}
                            />
                        </div>
                        <div className="notosanskr-400 font-size-14 errors c-f00">{errors.realname?.message}</div>
                    </Col>
                </Row>

                {/*nickname*/}
                <Row className="gx-0">
                    <Col className="w-200px col-sm col-sm-auto title-wrap" xs={12}>
                        <div className="title nickname-title notosanskr-500 font-size-17">
                            <span className="check-icon"></span>
                            {t("90")}
                        </div>
                    </Col>
                    <Col className="notosanskr-400 font-size-14 col-sm item-wrap" xs={12}>
                        <div>
                            <InputText
                                className="item"
                                id={ID.nickname}
                                name="nickname"
                                defaultValue={location.state?.name || ""}
                                placeholder={t("322")}
                                onBlur={async (e) => {
                                    if (isValid([e.target]))
                                        await onDuplicateNickname();
                                }}
                                disabled={!state.needCheckDuplicateNickname}
                                maxLength={20}
                            />
                        </div>
                        {errors.nickname?.state && errors.nickname?.message ?
                            <div className="notosanskr-400 font-size-14 errors c-ccc" id={ID.duplicatedName}>{errors.nickname?.message}</div>
                            :
                            <div className="notosanskr-400 font-size-14 errors c-f00" id={ID.duplicatedName}>{errors.nickname?.message}</div>
                        }
                    </Col>
                </Row>
                {/*phone*/}
                <Row className="gx-0">
                    <Col className="w-200px col-sm col-sm-auto title-wrap" xs={12}>
                        <div className="title phone-title notosanskr-500 font-size-17">
                            {t("327")}
                        </div>
                    </Col>
                    <Col className="notosanskr-400 font-size-14 col-sm item-wrap" xs={12}>
                        <PhoneForm phoneId={ID.phone}
                            errors={errors}
                            disabled={true}
                            defaultValue={location.state?.phone || ""}
                            font="notosanskr-400 font-size-14"
                        />
                    </Col>
                </Row>
                {/*address*/}
                <Row className="gx-0">
                    <Col className="w-200px col-sm col-sm-auto title-wrap" xs={12}>
                        <div className="title address-title notosanskr-500 font-size-17">
                            {t("329")}
                        </div>
                    </Col>
                    <Col className="notosanskr-400 font-size-14 col-sm item-wrap" xs={12}>
                        <PostcodeForm
                            address1Id={ID.address1}
                            address2Id={ID.address2}
                            isValid={isValid}
                            errors={errors}
                            disabled={true}
                            address1DefaultValue={location.state?.address1}
                            address2DefaultValue={location.state?.address2}
                            font="notosanskr-400 font-size-14"
                        />
                        <div className="c-f00">
                            {t("339")}
                        </div>
                    </Col>
                </Row>
                {/*self-introduce*/}
                <Row className="gx-0">
                    <Col className="w-200px col-sm col-sm-auto title-wrap" xs={12}>
                        <div className="title about-title notosanskr-500 font-size-17">
                            {t("340")}
                        </div>
                    </Col>
                    <Col className="notosanskr-400 font-size-14 col-sm item-wrap" xs={12}>
                        <div>
                            <textarea className="item" id={ID.about} name="about" disabled={true} placeholder={t("341")} maxLength={50} 
                            defaultValue={location.state?.about}/>
                        </div>
                    </Col>
                </Row>
                {/*SNS*/}
                <Row className="gx-0">
                    <Col className="w-200px col-sm col-sm-auto title-wrap" xs={12}>
                        <div className="title sns-title notosanskr-500 font-size-17">
                            {t("342")}
                        </div>
                    </Col>
                    <Col className="notosanskr-400 font-size-14 col-sm item-wrap" xs={12}>
                        <div>
                            <InputText className="item" id={ID.sns} name="sns" disabled={true} placeholder={t("343")} maxLength={500} 
                            defaultValue={location.state?.sns}/>
                        </div>
                    </Col>
                </Row>
                <div style={{ textAlign: "right" }}>
                    <button className="confirm-button notosanskr-400 font-size-16" onClick={onSubmit}>{t("79")}</button>
                </div>
            </div>
        </Container>
    );
}

import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { POPUP as GeneralPopup } from "../../common/defines";
import GeneralErrorWindow from "../common/GeneralErrorWindow";
import { Container, Row, Col } from "react-bootstrap";
import { useIsMount } from "../../common/customHook";
import Api from "../../Api";
import Editor from "../TinyEditor";
import "./Signup.css";
import Modal from "../common/Modal";
import CustomCheckbox from "../common/CustomCheckbox";
import CustomButtonLink from "../common/CustomButtonLink";
import dgLogger from '../../common/dgLogger';
import { useTranslation } from "react-i18next";
import common from "../../common/common";
import i18next from "i18next";
import { getAuth, GoogleAuthProvider, OAuthProvider, signInWithPopup, signInWithRedirect } from "firebase/auth";
import { auth } from "../../common/fbase.js";
import { isRunOnMobileAppWebview, registerMobileAppWebviewMessageHandler, sendMessageToMobileAppWebview, setHandleMobileAppWebviewMessages, unregisterMobileAppWebviewMessageHandler } from "../common/RNBridge.js";
import { error as E } from "@ocean-knight/shared";

const POPUP = {
    ...GeneralPopup,
    Terms:            1050,
    TermsWarning:     1051,
    FirebaseAuthFailure: 1052,
    SignUpDuplicatedEmail: 1053,
    SignUpBannedEmail: 1054,
};

export const PopupTermWarning = ({onRequestClose, onConfirm}) => {
    const icon = <img src={process.env.PUBLIC_URL + `/icon_accept_terms.png`} srcSet={`${process.env.PUBLIC_URL}/icon_accept_terms@2x.png 2x, ${process.env.PUBLIC_URL}/icon_accept_terms@3x.png 3x`} alt="" />;
    const header = <div>{i18next.t("314")}</div>;
    const body =  <div>{i18next.t("315").split('\n').map((v, i) => <div key={i}>{v}</div>)}</div>;

    return (
      <Modal
        onRequestClose={onRequestClose}
        onConfirm={onConfirm}
        icon={icon}
        header={header}
        body={body}
      />
    );
};

export function Signup() {
    const { t } = useTranslation();
    const [state, setState] = useState({
        isAgreeTerm: false,
        popup: POPUP.None,
        popupTarget: null,
        HTMLData: "",
    });
    const navigate = useNavigate();
    const isMount = useIsMount();

    const appleProvider = useCallback(() => {
        const provider = new OAuthProvider('apple.com');
        provider.addScope('email');
        provider.addScope('name');
        return provider;
    }, []);

    const googleProvider = useCallback(() => {
        const provider = new GoogleAuthProvider();
        return provider;
    }, []);


    /**
     * provider 에 따라 적절한 경로 (SignupEmail / SignupSocial) 로 navigate 수행
     * signup-social 로 이동할 경우, email 과 password 는 반드시 값이 있어야 한다.
     */
    const navigateToSignup = useCallback((provider, email = undefined, realname = undefined, password = undefined) => {
        const navigateTo = (provider == "email") ? "/Login/SignupEmail" : "/Login/SignupSocial";

        if (provider != "email") {
            console.assert(email);
            console.assert(password);
        }

        navigate(navigateTo, {
            replace: false,
            state: {
                provider: provider,
                email: email,
                realname: realname,
                uid: password
            }
        });
    }, [navigate]);

    const signup = useCallback(async (provider, email, realname, password/*a.k.a. uid*/) => {
        // 이미 등록된 사용자 인지 확인하여, 등록된 사용자/재가입 불가처리 사용자 라면 에러 팝업
        const payload = await Api.getSignedUserInfoViaFirebase({
            authProvider: provider,
            uid: password,
            email: email
        });
        const userInfo = payload?.userInfo;
        if (!userInfo) {
            // console.log(`has no signed user info of (${email}). navigate to signup`);
            return navigateToSignup(provider, email, realname, password);
        }

        if (userInfo.auth_provider?.[provider]?.uid) {
            console.info(`already ${payload.state == E.FOUND ? "signed-up" : "banned" } ${provider} social account`);
            switch (payload.state) {
                case E.BANNED:
                    return setState(prev => ({...prev, popup : POPUP.SignUpBannedEmail, popupTarget: email }));
                case E.FOUND:
                default:
                    return setState(prev => ({...prev, popup : POPUP.SignUpDuplicatedEmail, popupTarget: email }));
            }
        }

        navigateToSignup(provider, email, realname, password);
    }, [navigateToSignup]);

    useEffect(() => {
        document.getElementById("App").className = "login";
        Api.loadPrivacyPolicy({
            lang: common.getLang()
        })
            .then((payload) => {
                if (!isMount.current) return;
                const HTMLData = payload.HTMLData;
                setState((prev) => ({
                    ...prev, HTMLData: HTMLData ? HTMLData : ""
                }));
            })
            .catch((e) => {
                dgLogger.error(e)();
            });

        setHandleMobileAppWebviewMessages("signup", async ({provider, email, realname, password}) => {
            signup(provider, email, realname, password);
        });
        setHandleMobileAppWebviewMessages("firebase-auth-failure", ({provider, error}) => {
            setState(prev => ({ ...prev, popup: POPUP.FirebaseAuthFailure, popupTarget: {provider: provider, error: error } }));
        });
        return () => {
            setHandleMobileAppWebviewMessages("firebase-auth-failure", undefined);
            setHandleMobileAppWebviewMessages("signup", undefined);
        };
    }, [isMount, signup]);

    // 디자인 변경으로 기능도 함께 수정되어 현재 사용 되지 않는 popup
    // const popupTerms = () => {
    //     return (
    //         <Modal
    //             show={true}
    //             size="lg"
    //             id="signup-terms"
    //             backdrop="static"
    //             keyboard={false}
    //             centered
    //             onHide={() => {
    //                 setState({ ...state, popup: POPUP.None, popupTarget: null });
    //             }}
    //         >
    //             <Modal.Body>
    //                 <div>
    //                     &lt;이용약관&gt; 제1장 총칙 제1조 (목적) 이 약관은 “[회사명]”(이하 “[회사명]”)가 제공하는 “[서비스명] 및 관련 제반 서비스” (이하 ”서비스”)의 이용과 관련하여 회사와 회원과의
    //                     권리, 의무 및 책임사항 등을 규정함을 목적으로 합니다.
    //                 </div>
    //             </Modal.Body>
    //             <Modal.Footer>
    //                 <Button
    //                     onClick={() => {
    //                         document.getElementById('accept-terms').checked = true;
    //                         setState({ ...state, isAgreeTerm: true, popup: POPUP.None, popupTarget: null });
    //                     }}
    //                 >
    //                     동의
    //                 </Button>
    //                 <Button
    //                     onClick={() => {
    //                         document.getElementById('accept-terms').checked = false;
    //                         setState({ ...state, isAgreeTerm: false, popup: POPUP.None, popupTarget: null });
    //                     }}
    //                 >
    //                     취소
    //                 </Button>
    //             </Modal.Footer>
    //         </Modal>
    //     );
    // };

    const popupLoginFailure = (body) => {
        const icon = (<img src={process.env.PUBLIC_URL + `/pop_login_error.png`} srcSet={`${process.env.PUBLIC_URL}/pop_login_error@2x.png 2x, ${process.env.PUBLIC_URL}/pop_login_error@3x.png 3x`} alt="" />);
        const header = <div>{t('287')}</div>;

        return (
            <Modal
                onRequestClose={() => setState({ ...state, popup: POPUP.None, popupTarget: null })}
                onConfirm={() => setState({ ...state, popup: POPUP.None, popupTarget: null })}
                icon={icon}
                header={header}
                body={body}
            />
        );
    };

    const popupDuplicatedMail = (mail) => {
        const icon = <img src={process.env.PUBLIC_URL + `/pop_impossible.png`} srcSet={`${process.env.PUBLIC_URL}/pop_impossible@2x.png 2x, ${process.env.PUBLIC_URL}/pop_impossible@3x.png 3x`} alt="" />;
        const header = <div>{t('317')}</div>;
        const body = (
            <>
                <div className="c-4270e0">{mail}</div>
                <div>{t('318')}</div>
            </>
        );

        return (
            <Modal
                onRequestClose={() => {
                    setState({ ...state, popup: POPUP.None, popupTarget: null });
                }}
                onConfirm={() =>{
                    setState({ ...state, popup: POPUP.None, popupTarget: null });
                }}
                icon={icon}
                header={header}
                body={body}
            />
        );
    };

    const popupBannedMail = (mail) => {
        const icon = <img src={process.env.PUBLIC_URL + `/pop_impossible.png`} srcSet={`${process.env.PUBLIC_URL}/pop_impossible@2x.png 2x, ${process.env.PUBLIC_URL}/pop_impossible@3x.png 3x`} alt="" />;
        const header = <div>{t("319")}</div>;
        const body = (
            <>
                <div className="c-4270e0">{mail}</div>
                <div>{t("320")}</div>
            </>
        );

        return (
            <Modal
                onRequestClose={() => {
                    setState({ ...state, popup: POPUP.None, popupTarget: null });
                }}
                onConfirm={() =>{
                    setState({ ...state, popup: POPUP.None, popupTarget: null });
                }}
                icon={icon}
                header={header}
                body={body}
            />
        );
    };

    const popups = () => {
        switch (state.popup) {
            case POPUP.TermsWarning:
                return <PopupTermWarning
                    onRequestClose={() => setState({ ...state, popup: POPUP.None, popupTarget: null })}
                    onConfirm={() => setState({ ...state, popup: POPUP.None, popupTarget: null })}
                />;
            // case POPUP.Terms: return popupTerms();
            case POPUP.FirebaseAuthFailure: {
                const { provider, error } = state.popupTarget;

                const errorCode = error.code;
                const errorMessage = error.message;
                const errorContext = error.context;
                // The email of the user's account used.
                const email = error.customData?.email;
                errorCode && console.warn("errorCode: ", errorCode);
                errorMessage && console.warn("errorMessage: ", errorMessage);
                errorContext && console.warn("errorContext: ", errorContext);
                email && console.warn("email: ", email);

                const body = t("893").replace("[소셜명]", common.capitalizeFirstLetter(provider));
                return popupLoginFailure(<div>{body}</div>);
            }
            case POPUP.SignUpDuplicatedEmail: {
                const email = state.popupTarget;
                return popupDuplicatedMail(email);
            }
            case POPUP.SignUpBannedEmail: {
                const email = state.popupTarget;
                return popupBannedMail(email);
            }
            case POPUP.GeneralError:
                return <GeneralErrorWindow
                    message={state.popupTarget}
                    onClick={() => {
                        navigate("/");
                    }}
                />;
            default:
                console.log(`Can not handle ${state.popup} popup type`);
        }
    };

    const emailSignupString = useCallback(() => {
         const regex = /^(.*?)(\(.*?\))/gm;
        const result = regex.exec(t("312"));

        return <div className="lh-1.25 email-signup-string">
            <span className="font-size-18">
                {result[1].trim()}
            </span>
            &nbsp;
            <span className="font-size-12 d-block">
                {result[2].trim()}
            </span>
        </div>;
    }, [t]);

    return (
        <Container>
            <div className="login-sub-title">
                <span className="adobe-gothic-std-75 font-size-75 adobe-gothic-std-40:sm c-fff login">Join membership</span>
                <span className="notosanskr-600 font-size-24 notosanskr-14b:sm c-fff">{t("292")}</span>
            </div>
            {popups()}

            <div className="signup-page">
                <div className="membership notosanskr-700 font-size-36">Membership</div>

                <Row className="gx-0" style={{justifyContent: "space-between"}}>
                    <Col className="editor-wrap col-12 col-lg-6">
                        <div className="editor">
                            <Editor
                                key={true}
                                viewer={true}
                                HTMLData={state.HTMLData ? state.HTMLData : ""}
                            />
                        </div>
                        <div className="accept-terms notosanskr-400 font-size-14 notosanskr-12:sm">
                            <CustomCheckbox id="accept-terms">
                                <span className="">
                                {t("311")}
                                </span>
                            </CustomCheckbox>
                        </div>
                    </Col>
                    <Col className="button-wrap col-12 col-lg-6">
                        <Row className="gx-0">
                            {/* signup with email */}
                            <Col className="col-xl-12 col-12 col-md-6 pe-xl-0 pe-md-2 pb-4">
                                <button
                                    className="signup-email-button notosanskr-400 font-size-18 d-flex align-items-center"
                                    onClick={() => {
                                        if (!document.getElementById("accept-terms").checked) {
                                            setState({ ...state, popup: POPUP.TermsWarning, popupTarget: null });
                                        } else {
                                            navigateToSignup("email");
                                        }
                                    }}
                                >
                                    <Row className="gx-0 w-100 align-items-center">
                                        <Col className="col-12 w-100 text-center">
                                            { emailSignupString(t("312")) }
                                        </Col>
                                    </Row>
                                </button>
                            </Col>
                            {process.env.REACT_APP_SNS_REGISTER == "true" && <>
                            <Col className="col-xl-12 col-12 col-md-6 ps-xl-0 ps-md-2 pb-4">
                                {/* signup with google */}
                                <button
                                    className="signup-google-button notosanskr-400 font-size-18 d-flex align-items-center"
                                    onClick={async () => {
                                        const provider = "google";
                                        if (!document.getElementById("accept-terms").checked) {
                                            setState({ ...state, popup: POPUP.TermsWarning, popupTarget: null });
                                        } else {
                                            if (isRunOnMobileAppWebview()) {
                                                sendMessageToMobileAppWebview({type: "signup", social: true, provider: provider});
                                            } else {
                                                try {
                                                    const result = await signInWithPopup(auth(), googleProvider());
                                                    // 참고 : https://cloud.google.com/identity-platform/docs/web/google?hl=ko#signing_in_users_with_the
                                                    const user = result.user;
                                                    await signup(provider, user.email, user.displayName, user.uid);
                                                } catch (error) {
                                                    setState({ ...state, popup: POPUP.FirebaseAuthFailure, popupTarget: {provider: provider, error: error } });
                                                    // The AuthCredential type that was used.
                                                    const credential = GoogleAuthProvider.credentialFromError(error);
                                                    console.warn("google login error occurred, used credential: ", credential);
                                                };
                                            }
                                        }
                                    }}
                                >
                                    <Row className="gx-0 w-100 align-items-center">
                                        <Col className="col-12 w-100 text-center">
                                            {t('895')}
                                        </Col>
                                    </Row>
                                </button>
                            </Col>
                            <Col className="col-xl-12 col-12 col-md-6 pe-xl-0 pe-md-2 pb-md-4 signup-apple-button-wrap">
                                {/* signup with apple */}
                                <button
                                    className="signup-apple-button notosanskr-400 font-size-18 d-flex align-items-center"
                                    onClick={async () => {
                                        const provider = "apple";
                                        if (!document.getElementById("accept-terms").checked) {
                                            setState({ ...state, popup: POPUP.TermsWarning, popupTarget: null });
                                        } else {
                                            if (isRunOnMobileAppWebview()) {
                                                sendMessageToMobileAppWebview({type: "signup", social: true, provider: provider});
                                            } else {
                                                try {
                                                    const result = await signInWithPopup(auth(), appleProvider());
                                                    // 참고 : https://cloud.google.com/identity-platform/docs/web/google?hl=ko#signing_in_users_with_the
                                                    const user = result.user;
                                                    await signup(provider, user.email, user.displayName, user.uid);
                                                } catch (error) {
                                                    setState({ ...state, popup: POPUP.FirebaseAuthFailure, popupTarget: {provider: provider, error: error } });
                                                    // The AuthCredential type that was used.
                                                    const credential = OAuthProvider.credentialFromError(error);
                                                    console.warn("apple login error occurred, used credential: ", credential);
                                                }
                                            }
                                        }
                                    }}
                                >
                                    <Row className="gx-0 w-100 align-items-center">
                                        <Col className="col-12 w-100 text-center">
                                            {t('894')}
                                        </Col>
                                    </Row>
                                </button>
                            </Col>
                            </>}
                            <Col className="col-xl-12 col-12 col-md-6 ps-xl-0 ps-md-2 pb-4 notosanskr-400 font-size-16">
                                <Row className="gx-0 w-100 h-100 align-items-center d-flex justify-content-start">
                                    <Col className="col-xl-6 col-md-7 col-12 text-start text-xl-start text-lg-end pe-4 pb-2 pb-md-0">
                                        {t("313")}
                                    </Col>
                                    <Col className="col-md-5 col-xl-6 col-12 h-100">
                                        <CustomButtonLink className="login-button c-fff notosanskr-400 font-size-18" to="/Login/SignIn">
                                            {t("9")}
                                        </CustomButtonLink>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </Container>
    );
}
